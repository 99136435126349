import React, { lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Footer, Header } from "./components";

const routes = [
  {
    path: "/",
    Component: lazy(() => import("./Page/main")),
    exact: true,
  },
  {
    path: "/gallery",
    Component: lazy(() => import("./Page/gallery")),
    exact: true,
  },
];

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Route
        render={() => (
          <Switch>
            {routes.map(({ path, Component, exact }) => (
              <Route
                {...{ path, key: path, exact }}
                render={() => {
                  return (
                    <React.Suspense fallback={null}>
                      <Component />
                    </React.Suspense>
                  );
                }}
              />
            ))}
          </Switch>
        )}
      />
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
