import images2 from "../assets/images/05.jpg";

const AboutUs = () => {
  return (
    <section
      className="page-section-ptb parallax bg-overlay-black-60"
      style={{ background: `url(${images2})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <div className="section-title mb-8 md-mb-0">
              <h6 className="subtitle text-white">MUCHAS RAZONES PARA</h6>
              <h2 className="text-white">Por qué elegirnos</h2>
              <p className="text-white">
                Nuestro compromiso es tal que llegamos a sentirnos socios de
                nuestros clientes. Nuestra ganancia es conservarlo y que nos
                siga eligiendo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
