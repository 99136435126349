import { Link } from "react-router-dom";

const Services = () => {
  return (
    <section className="our-services page-section-ptb bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title text-center">
              <h6 className="subtitle">SOMOS BUENOS EN</h6>
              <h2 className="title">Nuestros servicios </h2>
              <p>
                ¡Haremos todo lo posible para hacer su proyecto, nuestro mayor
                proyecto!
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 sm-mb-3">
            <div className="card box-shadow text-center h-100 border-0">
              <div className="card-body p-4">
                <div className="mb-2">
                  <span className="flaticon-paint fa-4x"></span>
                </div>
                <h5>
                  <Link to="/gallery">
                    Remodelaciones y construcción domiciliaria
                  </Link>
                </h5>
                <p>
                  Experiencia en remodelaciones, construcción, ampliaciones,
                  terrazas, quinchos en distintos materiales constructivos de
                  manera de garantizar el mejor precio con lamedor calidad.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 sm-mb-3">
            <div className="card box-shadow text-center h-100 border-0">
              <div className="card-body p-4">
                <div className="mb-2">
                  <span className="flaticon-parquet fa-4x"></span>
                </div>
                <h5>
                  <Link to="/gallery">Cierres perimetrales</Link>
                </h5>
                <p>
                  Experiencia y comprobada calidad en soluciones de cierres
                  perimetrales, tanto de empresas como de faenas empresariales.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 sm-mb-3">
            <div className="card box-shadow text-center h-100 border-0">
              <div className="card-body p-4">
                <div className="mb-2">
                  <span className="flaticon-work fa-4x"></span>
                </div>
                <h5>
                  <Link to="/gallery">
                    Construcción, habilitación y mantención de campamentos de
                    Faena
                  </Link>
                </h5>
                <p>
                  Experiencia en trabajos asociados a los campamentos de faenas,
                  para llegar a las mejores soluciones constructivas,
                  habilitación de plantas de agua, habilitación de
                  infraestructura modulares de grandes y medianas empresas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
