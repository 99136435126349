import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import images1 from "../assets/images/14.jpg";

const Input = ({
  name,
  register,
  data_msg_required,
  required,
  type = "text",
  errors,
  placeholder,
}) => {
  let tmpRules = {};
  if (required) {
    tmpRules = {
      required: data_msg_required ?? "Este campo es requerido",
    };
  }
  return (
    <>
      <input
        {...register(name, tmpRules)}
        {...{
          type,
          maxlength: "100",
          className: "form-control",
          placeholder,
        }}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="alert alert-danger">
            <div role="alert">{message}</div>
          </div>
        )}
      />
    </>
  );
};

const TextArea = ({
  name,
  register,
  data_msg_required,
  required,
  placeholder,
  errors,
}) => {
  let tmpRules = {};
  if (required) {
    tmpRules = {
      required: data_msg_required ?? "Este campo es requerido",
    };
  }
  return (
    <>
      <textarea
        {...register(name, tmpRules)}
        {...{
          id: name,
          rows: 5,
          maxlength: "5000",
          className: "form-control",
          placeholder,
        }}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="alert alert-danger">
            <div role="alert">{message}</div>
          </div>
        )}
      />
    </>
  );
};

const ContacUs = () => {
  const [sended, setSended] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    fetch("https://eduon4f38h.execute-api.sa-east-1.amazonaws.com/Prod/send", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    setSended(true);
    setTimeout(() => {
      setSended(false);
      reset();
    }, 20000);
  };

  return (
    <section className="split-section bg-dark page-section-ptb">
      <div className="side-background">
        <div className="col-md-6 col-sm-12 img-side img-left">
          <div
            className="img-holder img-cover"
            data-jarallax='{"speed": 0.6}'
            style={{ backgroundImage: `url(${images1})` }}
          ></div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-6 col-md-12 pl-5 md-pl-2">
            <div className="contact-3-info contact-3">
              {sended ? (
                <div className="clearfix">
                  <div className="section-title">
                    <h6 className="subtitle text-white">MENSAJE RECIBIDO</h6>
                    <h2 className="text-white">
                      Hola, Gracias por contactarnos
                    </h2>
                  </div>
                  <p className="mb-5 text-white">
                    Nuestros representantes de soporte revisarán su mensaje y lo
                    reenviarán a la persona mejor indicada para atenderle. Nos
                    pondremos en contacto en un plazo de 48 horas.
                  </p>
                </div>
              ) : (
                <div className="clearfix">
                  <div className="section-title">
                    <h6 className="subtitle text-white">
                      SI TIENE ALGUNA PREGUNTA
                    </h6>
                    <h2 className="text-white">Contáctenos</h2>
                  </div>
                  <p className="mb-5 text-white">
                    No dude en enviarnos un mensaje.
                  </p>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="form-horizontal"
                  >
                    <div className="contact-form border-form clearfix">
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="section-field">
                            <Input
                              id="name"
                              type="text"
                              placeholder="Nombres"
                              data_msg_required="Ingrese su nombre."
                              register={register}
                              required
                              errors={errors}
                              className="form-control"
                              name="name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="section-field">
                            <Input
                              type="email"
                              placeholder="Correo electrónico"
                              data_msg_required="Ingrese su Correo electrónico."
                              data_msg_email="Por favor, ingrese una direccion email valida."
                              className="form-control"
                              register={register}
                              required
                              errors={errors}
                              name="email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="section-field textarea">
                            <TextArea
                              className="input-message form-control"
                              placeholder="Mensaje"
                              data_msg_required="Ingrese su mensaje."
                              register={register}
                              required
                              errors={errors}
                              rows="7"
                              name="message"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-theme  clearfix"
                        href="#"
                      >
                        Enviar
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContacUs;
