import { Link } from "react-router-dom";

import banner from "../assets/images/10.png";
import layer from "../assets/images/04.png";
import images11 from "../assets/images/renovation/11.jpg";
import images12 from "../assets/images/renovation/12.jpg";
import images13 from "../assets/images/renovation/13.jpg";

const Efficiency = () => {
  return (
    <section
      className="page-section-pt sm-pb-5"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 sm-mb-3">
            <div className="card card-overlay">
              <img className="img-fluid" src={images11} alt="" />
              <div className="card-info ">
                <h4 className="text-white mb-0">
                  <Link to="/">EFICIENCIA EN LA EJECUCIÓN</Link>
                </h4>
                <p className="text-white mb-0">
                  Realizar lo solicitado por el cliente de la forma más
                  correcta, segura y rápida posible.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 sm-mb-3">
            <div className="card card-overlay">
              <img className="img-fluid" src={images12} alt="" />
              <div className="card-info ">
                <h4 className="text-white mb-0">
                  <Link to="/">ATENCIÓN AL MÍNIMO DETALLE</Link>
                </h4>
                <p className="text-white mb-0">
                  Ser minuciosos cuidando los detalles más pequeños en cada
                  trabajo.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 sm-mb-3">
            <div className="card card-overlay">
              <img className="img-fluid" src={images13} alt="" />
              <div className="card-info ">
                <h4 className="text-white mb-0">
                  <Link to="/">COMPROMETIDOS CON LA CALIDAD</Link>
                </h4>
                <p className="text-white mb-0">
                  Hacemos partícipe a nuestros colaboradores de la calidad y
                  compromiso que deben asumir para dar la mejor atención a
                  nuestros clientes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 sm-mt-2">
          <div className="col-lg-7 col-md-12 md-mb-5">
            <div className="section-title">
              <h2>SOLUCIÓN DE IMPREVISTOS</h2>
              <p>
                Ante los imponderables presentes en los proyectos, se deberá
                buscar la solución de la mejor forma.
              </p>
            </div>
            <p>
              Para esto la mejor solución es la anticipación. Un cronograma de
              compras definido, tomando también todas las precauciones, puede
              evitar que inconvenientes sucedan durante el trabajo. La
              comunicación también es un factor importante, si llegan a ocurrir
              imprevistos o retrasos en la entrega de materiales, la
              constructora deberá informar, ya que estos contratiempos
              producirán retrasos en el proyecto.
            </p>
          </div>
          {/* <div className="col-lg-5 align-items-end">
            <img src={layer} className="img-fluid hidden-sm" alt="" />
          </div> */}
        </div>
        <img src={banner} className="img-fluid hidden-sm" alt="" />
      </div>
      <div
        style={{
          background: `url(${banner})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      />
    </section>
  );
};

export default Efficiency;
