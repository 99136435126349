import * as React from "react";
import $ from "jquery";

const Phrases = () => {
  React.useEffect(() => {
    let owlslider = $("div.owl-carousel");
    owlslider.each(function () {
      var $this = $(this),
        $items = $this.data("items") ? $this.data("items") : 1,
        $loop = $this.attr("data-loop") ? $this.data("loop") : true,
        $navdots = $this.data("nav-dots") ? $this.data("nav-dots") : false,
        $navarrow = $this.data("nav-arrow") ? $this.data("nav-arrow") : false,
        $autoplay = $this.attr("data-autoplay") ? $this.data("autoplay") : true,
        $autohgt = $this.data("autoheight") ? $this.data("autoheight") : false,
        $space = $this.attr("data-space") ? $this.data("space") : 30;

      $(this).owlCarousel({
        loop: $loop,
        items: $items,
        responsive: {
          0: { items: $this.data("xx-items") ? $this.data("xx-items") : 1 },
          480: { items: $this.data("xs-items") ? $this.data("xs-items") : 1 },
          768: { items: $this.data("sm-items") ? $this.data("sm-items") : 2 },
          980: { items: $this.data("md-items") ? $this.data("md-items") : 3 },
          1200: { items: $items },
        },
        dots: $navdots,
        autoHeight: $autohgt,
        margin: $space,
        nav: $navarrow,
        navText: [
          "<i class='fa fa-angle-left fa-2x'></i>",
          "<i class='fa fa-angle-right fa-2x'></i>",
        ],
        autoplay: $autoplay,
        autoplayHoverPause: true,
      });
    });
  }, []);

  return (
    <section className="page-section-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div
              className="owl-carousel mb-4"
              data-nav-dots="true"
              data-items="1"
              data-md-items="1"
              data-sm-items="1"
            >
              <div className="item">
                <div className="testimonial text-center">
                  <i className="fa fa-quote-left fa-2x text-theme"></i>
                  <p className="text-dark">
                    La veracidad de los materiales de construcción: hormigón,
                    ladrillos y piedra, se mantendrán en todos los edificios
                    construidos o que se construirán.
                  </p>
                  <h5 className="mb-0">Le Corbusier</h5>
                </div>
              </div>
              <div className="item">
                <div className="testimonial text-center">
                  <i className="fa fa-quote-left fa-2x text-theme"></i>
                  <p className="text-dark">
                    Cuando sueñas, la construccion de un mundo es una risa de
                    albañiles.
                  </p>
                  <h5 className="mb-0">Leopoldo Marechal</h5>
                </div>
              </div>
              <div className="item">
                <div className="testimonial text-center">
                  <i className="fa fa-quote-left fa-2x text-theme"></i>
                  <p className="text-dark">
                    Toda técnica de construcción logra al fin su forma eterna,
                    su expresión fundamental que a partir de entonces se
                    perfecciona y afina.
                  </p>
                  <h5 className="mb-0">Ernst Neufert</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Phrases;
