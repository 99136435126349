import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

import $ from "jquery";

const Header = () => {
  React.useEffect(() => {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $(".header-sticky").addClass("is-sticky");
      } else {
        $(".header-sticky").removeClass("is-sticky");
      }
    });
  }, []);

  return (
    <header className="header default header-sticky">
      {/*Navbar */}
      <nav className="navbar navbar-expand-lg ">
        <div className="container navbar-container">
          {/*Brand/Logo */}
          <Link className="navbar-brand" to="/">
            <img id="logo_img" src={logo} alt="" />
          </Link>
          <div className="d-inline-block">
            {/*Navbar toggler  */}
            <button
              className="navbar-toggler hamburger hamburger-js hamburger--spring"
              type="button"
              data-toggle="collapse"
              data-target="#navbar_main"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div
            className="collapse navbar-collapse align-items-center justify-content-end"
            id="navbar_main"
          >
            {/*Navbar search - For small resolutions */}
            {/*Navbar links */}
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link className="nav-link fw-6" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fw-6" to="/gallery">
                  Galería
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
