import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";

import "./assets/css/plugins-css.css";
import "./assets/css/typography.css";
import "./assets/css/shortcodes.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/flaticon.css";
import "./assets/css/swiper/swiper.min.css";
import "./assets/css/animate/animate.min.css";
import "./assets/css/skins/skin-green.css";

import jQuery from "jquery";

window.jQuery = jQuery;

// require("owl.carousel/dist/assets/owl.carousel.css");
require("owl.carousel");

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById("root")
);
