import React from "react";
import slider1 from "../assets/images/01.jpg";
import slider2 from "../assets/images/02.jpg";
import Swiper from "swiper";
import SwiperAnimation from "swiper-animation";
import $ from "jquery";

const styleBackground1 = {
  backgroundImage: `url(${slider1})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
};

const styleBackground2 = {
  backgroundImage: `url(${slider2})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
};

const Home = () => {
  React.useEffect(() => {
    let siperslider = $(".swiper-container");
    let swiperAnimation = new SwiperAnimation();

    new Swiper(".swiper-container", {
      init: true,
      direction: "horizontal",
      effect: "slide",
      loop: true,

      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      on: {
        init: function () {
          swiperAnimation.init(this).animate();
        },
        slideChange: function () {
          swiperAnimation.init(this).animate();
        },
      },
    });
  }, []);

  return (
    <div className="p-0">
      <div id="main-slider" className="swiper-container gardener carousel">
        <div className="swiper-wrapper h-900 h-md-500 h-sm-400 h-xs-300">
          <div
            className="swiper-slide align-items-center d-flex bg-overlay-black-40"
            style={styleBackground1}
          >
            <div className="swipeinner container">
              <div className="row justify-content-start">
                <div className="col-lg-7 col-md-10 col-sm-12 text-left">
                  <div className="slider-1">
                    <div className="d-flex align-items-center">
                      <span
                        className="flaticon-sprout fa-4x text-white d-none d-md-block"
                        data-swiper-animation="fadeInLeft"
                        data-duration="1s"
                        data-delay="0.90s"
                      ></span>
                      <h4
                        className="text-white pb-1 border-b mb-1 mt-0 mt-lg-1 ml-md-3 ml-0"
                        data-swiper-animation="bounceIn"
                        data-duration="1s"
                        data-delay="0.90s"
                      >
                        Creamos tus sueños
                      </h4>
                    </div>
                    <h1
                      className="text-white heading-extra-large text-uppercase mb-3 mt-3 fw-3"
                      data-swiper-animation="fadeInDown"
                      data-duration="1s"
                      data-delay="0.25s"
                    >
                      Remodelaciones<br></br>
                      <span className="fw-7"> HAZ REALIDAD TUS PROYECTOS </span>
                    </h1>
                    <h6
                      className="text-white mb-3 mt-2 mt-md-4 mb-md-4 d-none d-sm-block"
                      data-swiper-animation="fadeInLeft"
                      data-duration="1s"
                      data-delay="0.25s"
                    >
                      "No es verdad que lo útil sea bello, pero sí que lo bello
                      es útil.
                      <br></br> La belleza puede mejorar la forma de vivir de la
                      gente."
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="swiper-slide align-items-center d-flex bg-overlay-black-20"
            style={styleBackground2}
          >
            <div className="swipeinner container">
              <div className="row justify-content-end">
                <div className="col-lg-7 col-md-10 col-sm-12 text-right">
                  <div className="slider-1">
                    <div className="align-items-center">
                      <h4
                        className="text-white pb-1 border-b mb-1 mt-0 mt-lg-1 mr-3"
                        data-swiper-animation="bounceIn"
                        data-duration="1s"
                        data-delay="0.90s"
                      >
                        Construcciones de calidad
                      </h4>
                      <span
                        className="flaticon-sprout fa-4x text-white"
                        data-swiper-animation="fadeInLeft"
                        data-duration="1s"
                        data-delay="0.90s"
                      ></span>
                    </div>
                    <h1
                      className="text-white heading-extra-large text-uppercase mb-3 mt-3 fw-3"
                      data-swiper-animation="fadeInDown"
                      data-duration="1s"
                      data-delay="0.25s"
                    >
                      CIERRES PERIMETRALES<br></br>{" "}
                      <span className="fw-7"> CAMPAMENTOS DE FAENAS</span>
                    </h1>
                    <h6
                      className="text-white mb-3 mt-2 mt-md-4 mb-md-4 d-none d-sm-block"
                      data-swiper-animation="fadeInLeft"
                      data-duration="1s"
                      data-delay="0.25s"
                    >
                      "No se trata nada más de resguardarse, se trata de
                      manifestar la civilización a través de sorprendentes y
                      prácticas construcciones."
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="swiper-button-prev"
          tabindex="0"
          role="button"
          aria-label="Previous slide"
        >
          <i className="fa fa-angle-left"></i>
        </div>
        <div
          className="swiper-button-next"
          tabindex="0"
          role="button"
          aria-label="Next slide"
        >
          <i className="fa fa-angle-right"></i>
        </div>
      </div>
    </div>
  );
};

export default Home;
