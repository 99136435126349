import { Link } from "react-router-dom";

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="footer page-section-pt bg-black">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-6 md-mb-3">
            <div className="footer-useful-link footer-hedding">
              <h6 className="text-white mb-3 mt-1 text-uppercase">
                Navegación
              </h6>
              <ul className="p-0">
                <li>
                  <Link to="/">Inicio</Link>
                </li>
                <li>
                  <Link to="/gallery">Galería</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 md-mb-3">
            <h6 className="text-white mb-3 mt-1 text-uppercase">Contáctenos</h6>
            <ul className="p-0 addresss-info">
              <li>
                <i className="fa fa-map-marker"></i>{" "}
                <p>Dirección: Heriberto Covarrubias 21, 604, Ñuñoa</p>{" "}
              </li>
              <li>
                <i className="fa fa-phone"></i>Telefóno y whatsapp: +56 9 6125
                5028
              </li>
              <li>
                <i className="fa fa-envelope-o"></i>Correo Electrónico:
                <a href="mailto:contacto@voico.cl"> contacto@voico.cl</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-widget mt-5">
          <div className="row">
            <div className="col-md-6 text-center text-md-left">
              <p className="mt-2">
                {" "}
                ©Copyright {year}
                <span id="copyright"> </span> <a href="index-01.html"> </a>{" "}
                Todos los derechos reservados.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
