import { Link } from "react-router-dom";

const AboutUs1 = () => {
  return (
    <section className="special-feature page-section-pb">
      <div className="container">
        <div className="row no-gutter">
          <div className="col-md-4 sm-mb-3">
            <div className="card bg-gray text-center h-100 border-radius-none border-0">
              <div className="card-body">
                <div className="mb-2">
                  <span className="ti-desktop text-theme fa-4x"></span>
                </div>
                <h5 className="fw-6">
                  <Link to="/">Nuestra empresa</Link>
                </h5>
                <p>
                  Voico es una empresa especializada en remodelación y
                  construcción tanto a nivel residencial como empresarial. Con
                  personal con persona con mas de 30 años de experiencia en
                  soluciones constructivas.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 sm-mb-3">
            <div className="card bg-theme text-center h-100 border-radius-none border-0">
              <div className="card-body">
                <div className="mb-2">
                  <span className="ti-server text-white fa-4x"></span>
                </div>
                <h5 className="text-white fw-6">
                  <a href="service-detail.html">Nuestra misión</a>
                </h5>
                <p className="text-white">
                  Brindar a nuestros clientes servicios de construcción y
                  remodelación con los más altos estándares de calidad,
                  innovación tecnológica y de seguridad, así como con el
                  cumplimiento de los plazos de entrega.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card bg-dark text-center h-100 border-radius-none border-0">
              <div className="card-body">
                <div className="mb-2">
                  <span className="ti-heart text-theme fa-4x"></span>
                </div>
                <h5 className="fw-6 text-white">
                  <a href="service-detail.html">Nuestra visión</a>
                </h5>
                <p className="text-white">
                  Consolidarnos como una de las principales empresas de
                  construcción y remodelación del país, siendo una empresa en
                  continua evolución y expansión que trascienda y genere valores
                  sólidos y éticos con resultados de carácter sostenible,
                  dándole una organización ágil e innovadora a todos nuestros
                  proyectos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs1;
